.signup-overlay-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  overflow: auto;
  align-items: center;
  justify-content: center;
  z-index: 10;
  display: none;

  > * {
    display: inline-block;
    margin: 10px;
  }

  &.visible {
    display: flex;
  }
}

.signup-overlay {
  width: 96%;
  max-width: 860px;
  background: $white;

  &, * {
    box-sizing: border-box;
  }

  .signup-inner {
    padding: 10% 15%;
    position: relative;

    .close {
      position: absolute;
      top: 0px;
      right: 0px;
      font-size: 40px;
      padding: 10px 15px;
      cursor: pointer;
      &:after {
        content: 'Ã';
      }
    }

    h2 {
      font-size: 50px;
      margin: 0 0 15px;
    }
    p {
      font-size: 22px;
      margin: 0 0 15px;
    }
    form {
      padding: 20px 0;
      font-size: 0;
      div {
        display: inline;
      }
      input {
        display: inline-block;
        font-size: 22px;
        width: 70%;
        padding: 15px;
        border: 1px solid $black;
        vertical-align: top;
      }
      input[type="submit"] {
        background: $black;
        -webkit-border-radius: 0;
        border-radius: 0;
        width: 30%;
        text-align: center;
      }
    }
  }
}

h1.site-title {
  @include nav-type();
  text-transform: uppercase;
}

header#header {
  display: block;
  width: 100%;
  color: $black;
  max-width: $max-fluid;
  margin: 0 auto;
  min-height: 420px;
  background: $white;

  #top-bar {
    padding-top: 20px;
    text-transform: uppercase;
    @extend .clearfix;

    h1 {
      @include nav-type();
      display: inline-block;
      width: 5.5 * $col-width;
      white-space: nowrap;
      float: left;
      font-size: 20px;
      line-height: 16px;
      margin: 1em 0 1em 0.5 * $col-width;

    }

    nav {
      display: inline-block;
      margin-left: 6 * $col-width;
      float: left;
      width: 50%;

      ul {
        padding: 0;
        li {
          margin-right: 20px;
          display: inline-block;
          a {
            @include nav-type();
            color: $black;

            &:hover, &.current {
              color: $red-type;
            }
          }
        }
        li.logged-in {
          float: right;
          &, a {
            text-transform: none;
            @include regular-type;
            font-size: 1em;
          }
          a {
            color: $red-type;
            display: inline-block;
            margin-left: 5px;
          }
        }
      }
    }

    .burger-container {
      display: none;
      position: absolute;
      z-index: 5;
      top: 0;
      right: 0;
      padding: 6% $col-width;
      cursor: pointer;

      .burger-icon {
        @include burger(8vw, 3px, 1.5px, white);
        @include burger-middle {
          background-color: transparent;
        }
      }

      &.active {
        .burger-icon {
          @include burger-to-cross;
        }
      }
    }
  }

  #sub-header {
    $margin: 0.5 * $col-width;
    margin-left: $margin;
    margin-right: $margin;

    .desktop > h1 {
      margin-top: 0.5em;
      letter-spacing: -3px;
    }
    .header-image {
      float: right;
      width: 35%;

      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }

    p {
      @include medium-type();
      line-height: 1;
      font-size: 40px;
      letter-spacing: -1px;
    }

  }

  .hero {
    img {
      width: 100%;
      height: auto;
    }
  }

  .mobile {
    display: none;
  }

  div#mobile-nav-wrapper {
    display: none;
  }

}

body.repair-site header#header #top-bar nav ul li a.repair {
  color: $red-type;
}

body.catalogue-site, body.store-faq, body.shipping-page, body.thirty-point-check, body.warranty-page {
  header#header #top-bar nav ul li a.buy {
    color: $red-type;
  }
}

nav.mobile {
  display: none;
}

@media screen and (max-width: $tablet-max) {
  header#header {

    #sub-header .desktop {

      .shop-pages {
        display: none;
      }
    }
  }
}

@media screen and (max-width: $mobile-max) {
  header#header {
    float: none;
    min-height: 0;
    display: inline-block;
    width: 100%;

    #top-bar .mobile, #sub-header .mobile {
      display: block;
    }

    #top-bar .desktop {
      display: none;
    }

    #top-bar {
      margin-top: 2em;
      margin-bottom: 2em;
      @include product-column();
      float: none;

      h1 {
        margin-left: 0;
        width: auto;
      }

      .burger-container {
        display: block;
      }
    }

    .hero {
      display: none;
    }

    #sub-header .desktop {
      display: none;
    }

    #sub-header .mobile {
      margin-bottom: 1.2em;

      h1 {
        padding-bottom: 0.8rem;
        border-bottom: 4px solid;
      }
    }

    #sub-header {
      @include product-column();
    }
  }

  body.home nav.mobile-nav, nav.mobile-nav {
    display: block;
    font-size: 1rem;
    padding-bottom: 10em;
    @include product-column;
    margin-top: 2em;

    &, a {
      color: white;
    }

    &.red, &.red a {
      background-color: $background-red;
      color: black;
    }

    h1.site-title {
      @include nav-type();

    }

    > div.links {
      display: block;
    }
    $padding-left: 5%;

    ul {
      padding: 0;
      margin: 0;
      li {
        display: block;
        a {
          font-size: 1.5rem;
        }
      }
    }

    a {
      float: none;

      width: 100%;

      div.wrapper, div.wrapper:first-child {
        padding-left: 0;
      }

      div.wrapper {
        position: static;
        min-height: 0;
        display: block;

        .container {
          width: 100%;

          h1 {
            margin-top: 0.4em;
          }

          > p {
            min-height: 0; // Reset min-height
            font-size: 1.5rem;
            margin-top: 0.5em;
          }

          .enter-site {
            margin-bottom: 0.6em;
            p {
              font-size: 1.5rem;
              margin-bottom: 0.2em;
            }
          }
        }
      }
    }

    .catalogue-nav {
      margin-left: 0;

      ul li a {
        @include regular-type;
      }
    }

    div.links > ul {
      padding-bottom: 3em;
      border-bottom: 3px solid currentColor;

      > li {
        padding: 0.6em 0;
        border-top: 1px solid currentColor;
        a p {
          margin-bottom: 0;
        }
      }
    }

    .address {
      color: currentColor;
      margin-top: 3em;
    }
  }

  header#header div#mobile-nav-wrapper {
    position: absolute;
    z-index: 4;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: inline-block;
    margin-top: 0;
    font-size: 1em;
    @include transform(translate(100%, 0));

    &.active {
      @include transform(translate(0, 0));
      -webkit-transition: -webkit-transform 0.2s linear;
      -moz-transition: -moz-transform 0.2s linear;
      -o-transition: -o-transform 0.2s linear;
      transition: transform 0.2s linear;

    }
  }

  body.how-page, body.repair-site {
    header#header #sub-header .mobile {
      //margin-bottom: 0;

      h1 {
        border-bottom: 0;
        padding-bottom: 0;
      }

    }
  }
}

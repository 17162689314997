// Font types -----------------------------------------------------------------

@mixin regular-type {
  font-family: "Calibre Regular", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 400 !important;
  text-rendering: optimizeLegibility;
}

@mixin medium-type {
  font-family: "Calibre Medium", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 400 !important;
  text-rendering: optimizeLegibility;
}

@mixin oblique-type {
  @include regular-type();
  font-style: oblique;
}

@mixin bold-type {
  font-family: "Calibre Bold", Helvetica, sans-serif;
  font-weight: 400 !important;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

@mixin h1-type {
  // Example: headings on landing page
  @include medium-type();
  line-height: 1;
  margin: 0;
  font-size: 8.5em;
  //letter-spacing: -4px;
}

@mixin subheading-type {
  // Example case: underneath h1-type
  @include medium-type();
  line-height: 1;
  font-size: 52px;
  letter-spacing: -1px;
}

@mixin h2-type {
  // Example: "iphone 6" heading on product detail page
  line-height: 1;
  @include medium-type();

}

@mixin h3-type {
  // Example: section headings on the product detail page,
  // like the "Technicians report"
  @include medium-type();
  line-height: 1;

  @media screen and (max-width: $mobile-max) {
    font-size: 2em;
  }
}

@mixin nav-type {
  // used for items in the nav
  @include medium-type();
  line-height: 1;
  font-size: 1.1em;
  letter-spacing: -0.5px;
  word-spacing: 3px;

}

@mixin footer-type {
  line-height: 1;
}

@mixin paragraph-type {
  // Example:
  @include regular-type;
  line-height: 1;
  font-size: 20px;
}

@mixin small-detail-type {
  line-height: 1;
}

@mixin button-type {
  // type used inside buttons
  // Example: "Buy now" on product detail page
}

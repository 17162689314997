body {
  @include regular-type();
  position: relative;
}

table, input, textarea {
  @include medium-type;
  font-size: 19px;
  line-height: 1.3;
}

a {
  text-decoration: none;
  color: $black;
}

p > a {
  border-bottom: 1px solid;
  &:hover {
    border-bottom: none;
  }
}

img {
  display: block;
}

h1, h2, h3, h4 {
  //@include bold-type;
}

h1 {
  @include h1-type();

  @media screen and (max-width: $mobile-max) {

    font-size: 4rem;
    margin-top: 0.2em;

  }

}

h2 {
  @include h2-type();
}

h3 {
  @include h3-type();
}

h4 {
}

h5 {
}

h6 {
}

blockquote {

}

input[type="text"], input[type="password"], input[type="email"], textarea {
  @include regular-type;
  border: 1px lighten($faded-black, 50) solid;
  padding: 7px 10px;
  font-size: 16px;
  &:focus {
    outline: none;
    border-color: $faded-black;
  }
}

.checkbox-mask {
  @include checkbox-mask;
}

.radio-mask {
  @include radio-mask;
}

hr {
  margin: 0 15px;
  border-width: 10px 0 0;
  border-color: $black;
}

.fixedsticky-dummy {
  display: none;
}

@media screen and (min-width: $tablet-max) {
  .fixedsticky {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;

  }

  /* When position: sticky is supported but native behavior is ignored */
  .fixedsticky-withoutfixedfixed .fixedsticky-off,
  .fixed-supported .fixedsticky-off {
    position: static;
  }

  .fixedsticky-withoutfixedfixed .fixedsticky-on,
  .fixed-supported .fixedsticky-on {
    position: fixed;
  }

  .fixedsticky-dummy {
    display: none;
  }

  .fixedsticky-on + .fixedsticky-dummy {
    display: block;
  }
}

body.store-checkout {

  form {
    input[type=submit] {

    }
  }

  div#state-process {

  }

  div.order-wrapper {
    position: relative;
    background-color: $background-grey;
    margin: 100px auto;
    border-radius: 5px;
    display: block;

    width: ($total-cols - 1) * $col-width;
    max-width: $max-fluid * 0.75;

    div.container {
      width: 100%;
      padding: 0 (2 * $col-width) 20px (2 * $col-width);
      box-sizing: border-box;
      display: inline-block;

      h1 {
        font-size: 2.5em;
        border-bottom: 2px solid currentColor;
        margin-top: 1.5em;
        padding-bottom: 0.25em;
        letter-spacing: -0.5px;
      }

      h2 {
        font-size: 20px;

        &.your-info {
          border-bottom: 2px solid currentColor;
        }

        &.checkout {
          margin: 10px 0;
        }
      }

      > p {
        @include medium-type();
        font-size: 1.3em;
        margin-top: 0.5em;

      }

      ul {
        display: inline;
        padding: 0;
        margin: 0;

        li {
          box-sizing: border-box;
          display: block;
          list-style-type: none;
          float: left;

          .image {
            img {
              width: 100%;
              height: auto;
            }

            .product-name {
              min-height: 2em;
            }
          }

          .price {
            font-size: 20px;
            color: $background-red;
          }

          p {
            margin: 0;
          }

          input[type=submit], input[type=button] {
            padding-top: 8px;
            padding-bottom: 8px;
            max-width: 140px;
            margin-top: 10px;
            background-color: #454545;
          }

          &.current, &:hover {
            border-color: $get-real-with-grey;
            input[type=submit], input[type=button] {
              background-color: $get-real-with-grey;
            }
          }

        }

        &.accessories {
          @extend .clearfix;
          li {
            width: 28%;
            margin-left: 8%;
            margin-bottom: 40px;

            &:nth-child(3n+1) {
              margin-left: 0;
              clear: left;
            }

          }

        }

        &.warranties {
          li {
            width: 23%;
            padding: 25px 20px;
            margin-left: 3%;
            border: 3px solid transparent;
            border-radius: 5px;

            .title {
              font-size: 25px;
            }
          }
        }
      }

      .options {
        @extend .clearfix;

        > .image {
          float: left;
          width: 20%;
          display: block;

          img {
            width: 100%;
            height: auto;
          }
        }
      }

      .checkout-description {
        margin-top: 20px;

      }

      .checkout-next {
        text-align: right;
        width: 100%;
        margin-top: 3em;

        a {
          margin-right: 1em;
        }

        input[type=submit] {
          display: inline-block;
          background-color: $btn-green;
          max-width: none;
          width: auto;
          padding-right: 3em;
        }

        &:after {
          content: "â";
          display: block;
          position: absolute;
          @include vertical-align();
          right: 20px;
          line-height: 1em;
          color: white;
        }
      }


      form.delivery, form.login, form.account-details-form {
        width: 100%;
        padding-top: 15px;

        .form-field {
          display: inline-block;
          width: 33%;
          margin-bottom: 15px;
          vertical-align: top;
          margin-right: ($col-width / 2);
          min-width: 250px;
          // &:nth-child(2n+2) {
          //   margin-left: ($col-width / 2);
          // }

          input, select {
            width: 100%;
            box-sizing: border-box;
          }
          select {
            margin: 7px 0 0;
          }
          input[type="checkbox"] {
            // display: inline-block;
            width: auto;
          }

          .errorlist {
            display: block;
            margin: 10px 0 5px;
            li {
              float: none;
            }
          }
          .help {
            font-size: 0.8em;
            margin: 3px 0;
          }


        }
      }

      form.delivery {
        .user-form {
          padding: 20px 0;

          .save-details {
            width: 100%;
          }
        }
        .confirm {
          margin-top: 10px;
          display: block;
          width: 100%;

          ul {
            @extend .clearfix;
            display: block;

            &.errorlist {
              display: none;
            }
          }

          .checkbox {
            width: 100%;
            display: block;
            margin-bottom: 10px;

            input[type="checkbox"] {
              //display: none;
              display: inline-block;
            }
            input[type="checkbox"] + label span {
              //display:inline-block;
              //width:19px;
              //height:19px;
              //margin:-1px 4px 0 0;
              //vertical-align:middle;
              //background:url(check_radio_sheet.png) left top no-repeat;
              //cursor:pointer;
            }
            input[type="checkbox"]:checked + label span {
              //background:url(check_radio_sheet.png) -19px top no-repeat;
            }

            label {
              display: inline-block;
              //width: 100%;

              p {
                display: inline-block;
                margin-bottom: 0;
                padding-right: 20px;
              }
            }
          }

        }

        div.submit {
          position: absolute;
          bottom: 30px;
          right: (2 * $col-width);
          margin-right: 20px;
          min-width: 210px;
        }
      }
      form.login {
        input[type="submit"] {
          max-width: 120px;
          padding: 7px;
          text-align: center;
          background: black;
          margin-top: 17px;
        }
        .forgot {
          margin-left: 33% + $col-width / 2;
          font-size: 0.8em;
        }
        &.checkout-login {
        }
      }

      .logged-in {
        padding: 20px 0 10px;
      }
      form.cart {
        position: relative;
        margin-top: 10px;
        border-top: 2px solid currentColor;
        display: inline-block;
        width: 100%;
        $font-size: 16px;
        font-size: $font-size;

        ul.cart {
          display: inline-block;
          width: 100%;

          li.line, li.discounts, li.shipping {
            @extend .clearfix;
            width: 100%;
            display: inline-block;
            padding: 5px 0;
            border-top: 1px solid currentColor;

            &:last-child {
              border-bottom: 1px solid currentColor;
            }

            .common-item {
              float: left;
              display: block;
            }

            .product {
              @extend .common-item;
              width: 50%;
            }

            .quantity {
              @extend .common-item;
              width: 5%;
              position: relative;

              .x {
                position: absolute;
                right: 100%;
              }

              input {
                background: none;
                border: none;
                text-align: left;
                display: inline-block;
                width: 100%;
                padding: 0

              }
            }

            .remove, .update {
              @extend .common-item;
              width: 10%;
              background: none;
              border: none;
              text-align: left;
              font-size: 10px;
              line-height: $font-size;
            }

            .update {
              color: $black;
            }

            .remove {
              color: $background-red;
            }

            .price {
              @extend .common-item;
              width: 15%;
              float: right;
            }
          }
          li.discounts,
          li.shipping {
            .code,
            .options {
              float: left;
              width: 75%;
              input[type="text"],
              select {
                margin-left: 15px;
                padding: 1px 3px;
              }
              input[type="submit"] {
                background: none;
                border: none;
                text-align: left;
                font-size: 10px;
                line-height: $font-size;
                color: $black;
              }
            }
            .value {
              float: right;
              width: 15%;
              font-size: 20px;
              color: $green;
              position: relative;
              &:before {
                content: '-';
                position: absolute;
                left: -10px;
                top: 0;
              }
            }
          }
        }
        .attributes {
          display: block;
          width: 50%;
          position: absolute;
          font-size: 12px;

          .shipping {
            margin-top: 10px;
            p {
              margin-bottom: 5px;
            }

          }

          .gst {
            .key {
              display: inline-block;
            }
            .value {
              margin-left: 20px;
              display: inline-block;
            }
          }

        }
      }

      .total {
        margin-top: 10px;
        margin-bottom: 80px;
        display: inline-block;
        padding-left: 75%;
        width: 100%;
        box-sizing: border-box;
        text-align: left;

        h6 {
          @include medium-type();
          font-size: 25px;
          text-transform: uppercase;
          letter-spacing: -1px;

          .cost {
            display: inline-block;
            margin-left: 12px;

          }
        }

      }

      ul.errorlist {
        display: inline-block;
        color: red;

        li {
          display: inline-block;
        }
      }

      div.cancel-checkout {
        position: absolute;

        top: 100%;
        left: 5px;
        margin-top: 10px;

        a {
          color: white;
          margin-right: 1em;

          &:hover {
            text-decoration: underline;
          }

        }
      }
    }

  }
}


@font-face{
    font-weight: 400;
    font-family: "Calibre Regular";
    src: url("../../webfonts/CalibreWeb-Regular.eot") format("eot");
}
@font-face{
    font-weight: 400;
    font-family: "Calibre Regular";
    src: url("../../webfonts/CalibreWeb-Regular.eot");
    src: url("../../webfonts/CalibreWeb-Regular.woff") format("woff");
}


@font-face {
    font-weight: 500;
    font-family: "Calibre Medium";
    src: url("../../webfonts/CalibreWeb-Medium.eot") format("eot");
}

@font-face {
    font-weight: 500;
    font-family: "Calibre Medium";
    src: url("../../webfonts/CalibreWeb-Medium.eot");
    src: url("../../webfonts/CalibreWeb-Medium.woff") format("woff");
}

/* @font-face{
    font-weight: 600;
    font-family: "Calibre Semibold";
    src: url("../../webfonts/CalibreWeb-Semibold.eot") format("eot");
}
@font-face{
    font-weight: 600;
    font-family: "Calibre Semibold";
    src: url("../../webfonts/CalibreWeb-Semibold.eot");
    src: url("../../webfonts/CalibreWeb-Semibold.woff") format("woff");
} */

@font-face {
    font-weight: 700;
    font-family: "Calibre Bold";
    src: url("../../webfonts/CalibreWeb-Bold.eot") format("eot");
}

@font-face {
    font-weight: 700;
    font-family: "Calibre Bold";
    src: url("../../webfonts/CalibreWeb-Bold.eot");
    src: url("../../webfonts/CalibreWeb-Bold.woff") format("woff");
}

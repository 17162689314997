.catalogue-nav {
  background: $black;
  padding-left: 2.08333%;
  margin-left: 0;

  @include bleed($black);
}

body.catalogue-site {

  div#content {
    > div.red,
    .sub-menu {
      background-color: $bile-blue;
    }
    .sub-menu {

    }
  }


  div#catalogue-filters {
    @extend .clearfix;
    top: 0;
    background-color: white;
    z-index: 1;

    div.select-wrapper {
      @include select-wrapper();
      float: left;
      margin-left: $col-width / 2;
      width: 14.2%;
      margin-top: 2.5em;
      margin-bottom: 2.5em;
      font-size: 1em;

      @media screen and (max-width: $mobile-max) {
        margin-top: 0;
        margin-left: $col-width;
        width: $col-width * ($total-cols - 2);

        &:first-child {
          margin-top: 40px;
        }

      }
    }
  }

  div#catalogue, div#related-products {
    @extend .clearfix;
    margin-bottom: 80px;
    .product {
      @extend .product-layout;

      &:nth-child(3n + 1) {
        clear: left;
      }

      &:nth-child(1), &:nth-child(2), &:nth-child(3) {
        border-top: 0;
      }

      @media screen and (max-width: $mobile-max) {
        &:first-child {
          border-top: none;
        }
      }
    }
  }

  div#related-products {
    margin-top: 120px;
    margin-bottom: 80px;

    h3 {
      margin-bottom: 5px;
      margin-left: $col-width / 2;
    }
    .product {
      margin-top: 10px;
    }
  }

  div#image-slider {
    width: 100%;
    // 100 / 2580 * 1450
    height: 56vw;
    overflow-y: hidden;

    .slick-prev:before,
    .slick-next:before {
      color: black;
    }

    .panel {
      position: relative;

      .image {
        width: 100%;
        margin: 0 auto;

        img {

          display: block;
          width: 100%;
          height: auto;
        }
      }

      .notes {
        position: absolute;
        width: 7.3333333 * $col-width;
        bottom: 25%;
        left: 15.83333333 * $col-width;
        //background-color: rgba(0, 0, 0, 1);
        padding: 20px 10px 15px 0;
        border-bottom: 1px solid white;
        font-size: 12px;

        h3 {
          text-transform: uppercase;
        }

      }
    }

  }
  div#product-listing {
    @extend .clearfix;
    position: relative;

    h1 {
      @include medium-type();
      font-size: 6vw;
      line-height: 0.85;
      $margin-left: 8.3333333 * $col-width;
      margin: 40px 0 0 $margin-left;
    }

    h2 {
      @include regular-type();
      font-size: 6vw;
      line-height: 0.85;
      $margin-left: 8.3333333 * $col-width;
      margin: 0 0 20px $margin-left;
    }

    .rating {
      display: block;
      position: absolute;
      $circle-radius: 40px;
      right: $col-width;
      top: 40px;
      width: 2 * $circle-radius;
      height: 2 * $circle-radius;
      color: $red-type;
      border: 2px solid currentColor;
      border-radius: $circle-radius;
      box-sizing: border-box;

      .value {
        text-align: center;
        position: relative;
        line-height: (2 * $circle-radius) - 4px;
        width: 100%;
        display: block;
        font-size: 40px;
      }

      .annotation {
        position: absolute;
        font-size: 9px;
        text-transform: uppercase;
        top: 13px;
        left: 50%;
        @include transform(translateX(-50%));
      }
    }

    .sidebar {
      @include product-column();
      border-top: 4px solid $black;
      border-bottom: 2px solid $black;

      .price {
        color: $price-red;
        font-size: 4em;
        @include medium-type();
        margin: 0.2em 0;
      }

      .sold-out {
        font-size: 1.8em;
        margin: 0 0 10px;
      }
      .notify-me {
        margin: 0 0 30px;
        label {
          margin: 0 0 10px;
          display: block;
        }
        input[type="email"] {
          width: calc(70% - 22px);
          border-color: $black;
        }
        input[type="submit"] {
          width: 30%;
          background: $black;
          padding: 7px 10px;
          @include rounded(0);
          text-align: center;
        }
      }
      form#buy-now-form {
        input[type=submit] {
          background: black;
          padding-left: 0;
          padding-right: 0;
          text-align: center;
          display: inline-block;
        }
      }
      .wishlist-form {
        padding: 10px 0 0;
        input[type="submit"] {
          text-align: center;
          background: $bggrey;
        }
      }
      .in-wishlist {
        display: block;
        padding: 15px 0;
        border: none;
        // color: white;
        // padding: 12px 12px 12px 20px;
        // font-size: 17px;
        // border-radius: 5px;
        // width: 100%;
        // max-width: 200px;
        // text-align: left;
      }

      .rush {
        width: 100%;
        text-align: center;
        background-color: #cccccc;
        color: $black;
        font-size: 12px;
        padding: 15px 20px;
        box-sizing: border-box;
        margin-top: 20px;

        p {
          margin-bottom: 0;
        }
      }

      .fine-print {
        margin-top: 20px;
        border-top: 1px solid black;

        h3 {
          font-size: 1.3em;
          margin-top: 0.5em;
          margin-bottom: 0;
        }

        ul {
          padding: 0;
          margin-top: 1.5em;

          li {
            margin-bottom: 1.5em;
            list-style-type: none;
            font-size: 0.8em;

            strong {
              display: block;
              font-size: 1.5em;

              font-weight: 400 !important;
            }
          }
        }

      }
    }

    .product-content {
      float: left;
      margin-left: $col-width / 2;
      width: 15.16666666 * $col-width;
      border-top: 4px solid $black;

      .mobile {
        display: none;
      }

      > h3 {
        margin: 10px 0 0 0;
        padding-bottom: 10px;
        border-bottom: 1px solid;
      }

      .quality-grade {
        margin-top: 10px;
        margin-bottom: 5px;
        font-size: 15px;
      }

      .technician-profile {
        float: left;
        width: 20%;

        .image {
          margin-right: 10px;
          margin-bottom: 10px;

          img {
            width: 100%;
            height: auto;
            border-radius: 50%;
            @include greyscale(1);
          }
        }

        p {
          margin-bottom: 0;
          font-size: 13px;
        }

      }

      .wrapper {
        @extend .clearfix;
        width: 90%;
        display: inline-block;

        .left-column {
          box-sizing: border-box;
          float: left;
          width: 80%;
          padding-right: 25px;
          font-size: 16px;

          .fine-print {
            font-size: 12px;
            margin-bottom: 20px;
          }
        }
      }

      .summary-checklist {
        padding-left: 0;
        width: 100%;
        display: block;
        border-top: 1px solid $textgrey;

        li {
          list-style-type: none;
          display: inline-block;
          width: 23%;
          vertical-align: top;
          padding-top: 10px;

          border-right: 1px solid $textgrey;

          &:last-child {
            border-right: none;
          }

          p {
            font-size: 0.8em;
            padding: 0 4em 0 1em;
            margin-bottom: 7px;

            &:first-child {
              @include medium-type();
              min-height: 2em;
            }
          }
        }
      }

      .point-checklist {
        margin-top: 40px;

        h3 {
          margin: 0 0 20px 0;
          color: $red-type;
          font-size: 16px;
        }

        ol {
          display: none;

          li {
            &:before {
            }
          }
        }

      }

      .tech-specs {
        border-top: 2px solid $black;
        > ul {
          padding: 0;
          margin: 0 0 20px 0;

          li {
            list-style-type: none;
            display: inline-block;
            min-width: 25%;
            font-size: 14px;

            a {
              display: block;
              width: 100%;
              padding-right: 10px;
              padding-top: 17px;
            }

            &:first-child {
              border-right: 1px solid;
            }

            &:nth-child(2) {
              margin-left: 20px;
            }
          }
        }
        #specs {
          @extend .clearfix;
          font-size: 13px;

          ul {
            padding: 0;
            margin: 0;
            width: 30%;
            display: inline-block;
            vertical-align: top;

            li {
              @include list-style();
            }
          }

          .details {
            width: 30%;
            display: inline-block;
            margin-left: 3%;
            vertical-align: top;
            padding-right: 10px;
            box-sizing: border-box;

          }
          .model-image {
            width: 35%;
            display: inline-block;
            vertical-align: top;

            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }

      #product-comparison {
        overflow-x: scroll;
        width: 100%;
        display: none;
        color: $faded-black;

        table {
          min-width: 100%;

          tbody {
            tr {
              td {
                padding: 10px;
                min-width: 60px;
                text-align: left;
                font-size: 14px;

                &:first-child {
                }
              }

              border-bottom: 1px solid currentColor;

            }
          }
        }

      }
    }
  }
}

// body.catalogue-site.listing {
//   background: $black;
// }
body.catalogue-site.listing header#header {
  min-height: initial;

  // background: $black;
  // color: $white;
  //
  // #top-bar nav ul li a {
  //   color: $white;
  // }

  .sub-header-wrap {
    position: relative;
    @include bleed($black);

  }
  div#sub-header {
    margin: 0;
    background-color: black;
    color: white;
    @include clearfix();


    ul {
      margin: 15px 0;
    }

    .catalogue-nav {
      margin-left: 0.5 * $col-width;
      width: 11.5 * $col-width;
      position: relative;
      float: left;

      &:before, &:after {
        display: none;
      }
    }

    .shop-pages {
      float: left;
      margin-left: 0;
    }

    hr {
      border-color: $background-red;
      margin: 0;
      border-width: 1px 0 0;
    }

    .annotation {
      margin: 15px 0 15px $col-width / 2;
      font-size: 14px;
      text-transform: uppercase;

    }

  }
}

@media screen and (max-width: $mobile-max) {
  body.catalogue-site.listing header#header {
    div#sub-header {
      @include product-column;

      .annotation {
        margin-left: 0;
      }
    }
  }

  body.catalogue-site.listing {
    div#product-listing {
      //@extend .clearfix;
      //position: relative;

      h1 {
        //@include medium-type();
        //font-size: 6vw;
        //line-height: 0.85;
        //$margin-left: 8.3333333 * $col-width;
        //margin: 40px 0 0 $margin-left;

      }

      h2 {
        //@include regular-type();
        //font-size: 6vw;
        //line-height: 0.85;
        //$margin-left: 8.3333333 * $col-width;
        //margin: 0 0 20px $margin-left;
      }

      h1, h2 {
        @include product-column();
        font-size: 10vw;
      }

      .sidebar {
        position: relative;
        border-bottom: 1px solid currentColor;

        .price {
          margin: 0;
          line-height: 1;
          top: 8vw;
          position: absolute;
          font-size: 10vw;
        }

        form#buy-now-form {
          text-align: right;
          margin: 8vw 0;
          input[type=submit] {
            //background: black;
            font-size: 4.5vw;
            padding: 0.5em 0;
            max-width: none;
            width: 35%



          }
        }

        .rush {
          display: none;
        }

        .fine-print {
          display: none;
        }
      }

      .product-content {
        float: none;
        @include product-column();
        position: relative;
        border-top: none;

        .mobile {
          display: block;
        }

        .desktop {
          display: none;
        }

        > h3 {
          //margin: 10px 0 0 0;
          //padding-bottom: 10px;
          //border-bottom: 1px solid;
          border-top: 1px solid;
          //font-size: 2em;
          padding: 0.5em 0;
        }

        .technician-profile {
          float: none;
          width: 100%;
          margin-bottom: 2em;

          .image {
            //margin-right: 10px;
            //margin-bottom: 10px;
            width: 30%;
            margin: 1em 0;

            img {
              //width: 100%;
              //height: auto;
              //border-radius: 50%;
              //@include greyscale(1);
            }
          }

          p {
            //margin-bottom: 0;
            //font-size: 13px;
            font-size: 1em;
          }

          .rating {
            position: absolute;
            right: $col-width;
            top: 3em;
            $circle-radius: 9vw;

            width: 2 * $circle-radius;
            height: 2 * $circle-radius;
            border: 4px solid currentColor;
            border-radius: $circle-radius;

            .value {
              line-height: (2 * $circle-radius);
              font-size: 9vw;
              @include medium-type();
            }

            .annotation {
              font-size: 2.5vw;
              top: 2.5vw;
            }

          }

        }

        .quality-grade {
          //margin-top: 10px;
          //margin-bottom: 5px;
          //font-size: 15px;
          font-size: 1.1em;
        }

        .wrapper {
          //@extend .clearfix;
          //width: 90%;
          //display: inline-block;

          .left-column {
            //box-sizing: border-box;
            //float: left;
            //width: 80%;
            //padding-right: 25px;
            //font-size: 16px;
            width: 100%;
            padding-right: 0;

            .fine-print {
              //font-size: 12px;
              //margin-bottom: 20px;
              font-size: 1em;
              margin-top: 0.5em;
            }
          }

        }

        .summary-checklist {
          display: none;
        }

        .point-checklist {
          display: none;
        }

        .tech-specs {
          display: none;
        }
      }
    }

    div#related-products {
      margin-top: 4em;
      margin-bottom: 2em;

      h3 {
        //margin-bottom: 5px;
        //margin-left: $col-width / 2;
        margin-left: $col-width;
      }
      .product {
        //margin-top: 10px;
        border-top: 4px solid;
      }
    }
  }
}

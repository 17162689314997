body {
  background-color: $white;

  &.repair-site, &.faq-page, &.how-page, &.cms-page, &.terms-page {
    div#content {
      background-color: $background-red;
    }

    @media screen and (max-width: $mobile-max) {
      background-color: $background-red;

      #sub-header {
        color: black;
      }

      header div#mobile-nav-wrapper {
        background-color: $background-red;
        color: black;
      }
    }
  }

  &.catalogue-site {
    div#content {
      background-color: $background-white;
    }

    @media screen and (max-width: $mobile-max) {

      header div#mobile-nav-wrapper {
        background-color: black;
        color: white;
      }
    }
  }

}


body.home {
  background: $black;
}
body.repair-site {
  background: $black;

  header#header {
    background: $black;
    color: $white;

    #top-bar nav ul li a {
      color: $white;
    }
  }
}


@mixin circle($width) {
  width: $width;
  height: $width;
  margin: -$width / 2;
  @include rounded($width / 2);
}

#intro, #book {
  background: $background-red;
  color: #fff;
}

.radio-dropdown {
  $font-size: 32px;
  $padding-top: 25px;
  $padding-bottom: 25px;
  $height: $font-size + $padding-bottom + $padding-top + $border-width * 2;
  list-style: none;
  color: $tree-text;
  //@include bold-type;
  font-size: $font-size;
  text-transform: uppercase;
  position: relative;
  z-index: 2;

  > li.current label {
    text-align: center;
  }

  li {
    margin: 0 0 (-$border-width) 0;
    label {
      display: block;
      padding: $padding-top 90px $padding-bottom 20px;
      border: $border-width solid $tree-text;
      line-height: 1;
      color: $tree-text;
      background: $background-red;
    }

  }
  & {
    // styles which apply after js mods, should perhaps be
    // enabled by a class?

    li {
      display: none;
      input {
        display: none;
      }
      label {
        cursor: pointer;
      }
    }
    li.current {
      display: block;

      label {
        position: relative;
        &:after {
          content: 'â¼';
          text-align: center;
          line-height: $height;
          border-left: $border-width solid $tree-line;
          width: $height - $border-width * 2;
          font-size: 18px;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
        }
        &:before {
          content: "I'm ";
        }
      }
    }
    &.expanded {
      li {
        display: block;
      }
    }
  }

  @media screen and (max-width: 660px) {
    font-size: 20px;
    &.radio-dropdown {
      li.current {
        label:after {
          width: 72px;
        }
      }
    }
  }
  @media screen and (max-width: 479px) {
    font-size: 18px;

  }
}

.no-touch {
  .radio-dropdown {
    li label:hover {
      background: $tree-hover-background;
      color: $tree-hover-text;
    }
    li.current:hover {
      background: $background-red;

      &:after {
        background: $background-red;
      }
    }
  }
}

#book {
  @extend .noselect;
  padding: 30px 0;
  //    color: $mediumred;
  h2 {
    text-align: center;
    font-size: 14vw;
    padding-bottom: 20px;
    margin: 0 0 5vw;
    letter-spacing: -0.3vw;

    @media screen and (min-width: 1200px) {
      font-size: 168px;
      letter-spacing: -3px;
    }
  }

  @media screen and (max-width: $mobile-max) {
    padding: 0;
  }

  #id_location {
    padding: 0 $side-padding;
    margin: 0 auto;
    max-width: 600px;

    @media screen and (max-width: $mobile-max) {
      font-size: 18px;
      padding: 0 $col-width;

      &.radio-dropdown {
        li.current {
          label:after {
            width: 72px;
          }
        }
      }
    }
  }

  .services {
    color: $services-colour;
    h3, .service-category, .blurb {
      padding: 0 $side-padding;
      text-align: left;
      max-width: 680px;

      @media screen and (max-width: $mobile-max) {
        padding: 0 $col-width;
      }

    }
    h3 {
      margin: 0 auto 20px;
    }
    .blurb {
      margin: 0 auto 40px;
    }
    &.active {
      h3 {
        //color: #fff;
      }
    }
    .service-category {
      margin: 0 auto 5px;
      h4 {
        margin: 0.5em 0 0.5em;
        font-size: 28px;
      }
      p {
        @include regular-type;
        font-size: 14px;
        margin: 0 0 1.5em;
      }
      &:hover, &.checked {
        h4, p {
          color: #fff;
        }
      }
      .service {
        $width: 220px;
        margin: 0;
        display: inline-block;
        line-height: 1;
        position: relative;
        @include clearfix;
        font-size: 18px;
        // min-height: 65px;
        width: $width;

        label {
          min-height: 30px;
          padding: 0 0 2px 0;
          text-align: left;
          width: $width - 80px;
          padding-left: 40px;
          position: relative;
          float: left;

          .radio-mask,
          .checkbox-mask {
            position: absolute;
            top: 0;
            left: 0;
            @include checkbox-mask;
          }
          &:hover, &.checked {
            color: #fff;
            .checkbox-mask, .radio-mask {
              border-color: #fff;
              background: #fff;
            }
          }

          // &:hover .checkbox-mask,
          // &:hover .checkbox-mask,
          // &:hover .radio-mask,
          // .checkbox-mask.checked,
          // .radio-mask.checked {
          //     border-color: #fff;
          //     background: #fff;
          // }
        }
        .price {
          float: left;
          text-align: left;
          padding-left: 40px;
          margin: 0;
          &:before {
            content: '$';
          }
        }

        @media screen and (min-width: 600px) {
          &.long {
            width: 444px;
            label {
              width: 444px - 80px;
            }
          }
        }
      }

      @media screen and (max-width: 959px) {
        padding: 0 $col-width;
        max-width: 600px;
        min-width: $min-width - $side-padding * 2 - 10px;
        @include clearfix;

        .service {
          float: left;
          width: 50%;
          margin-bottom: 15px;
          label {
            width: auto;
            display: block;
            padding-right: 10px;
            float: none;
          }
        }
      }
      @media screen and (max-width: $col-width) {
        padding: 0 $col-width;

        .service {
          float: none;
          width: auto;
          display: block;
          margin-bottom: 15px;
          min-height: 45px;

          label {
            min-height: 0;
          }
        }
      }

      &,
      .service {
        &.hidden {
          display: none;
        }
      }
    }
  }

  .submit {
    $height: 100px;
    border: $border-width $book-order-border solid;
    width: 600px;
    margin: 30px auto 100px auto;
    @include clearfix;

    .total, input {
      color: $book-order-text;
      text-transform: uppercase;
      @include bold-type;
      font-size: 30px;
    }
    .total {
      float: left;
      width: 50%;
      line-height: $height;
      text-align: center;
    }
    input {
      height: $height;
      width: 50%;
      float: right;
      border: none;
      border-left: $border-width $book-order-border solid;
      margin-left: -$border-width;
      background: none;
      border-radius: 0;
    }

    @media screen and (max-width: 959px) {
      width: 400px;
      .total {
        width: 60%;
      }
      input {
        width: 40%;
      }
      .total, input {
        font-size: 22px;
      }
    }
    @media screen and (max-width: 479px) {
      width: 240px;
      .total, input {
        float: none;
        display: block;
        width: 100%;
      }
      .total {
        border-bottom: $border-width $mediumred solid;
      }
      input {
        border: none;
      }
    }
  }
  form.valid {
    .submit {
      &, input {
        //border-color: $mediumred;
      }
      .total, input {
        //color: $mediumred;
      }
      input {
        &:hover, &:active, &:focus {
          color: $tree-hover-text;
          outline: none;
          background: $tree-hover-background;
        }
      }
    }
  }
}

#info {
  padding: 30px 0;
  background: $bggrey;
  border-top: 15px #fff solid;
  color: #fff;
  h2 {
    text-align: center;
    margin: 0 0 8vw;
    @include vw_min_max(font-size, 12, 320px, 1200px);
  }
}

$step-border-width: $border-width;
$step-height: 78px;
.steps {
  list-style: none;
  padding: 0 $side-padding;
  margin: 0 auto 100px;
  color: $black;
  max-width: 980px;
  li {
    $padding-left: $step-height - $step-border-width;
    padding: 0 0 10px $padding-left;
    position: relative;
    min-width: $min-width - $side-padding * 2 - $padding-left;
    max-width: 480px;
    margin: 0 auto;

    h4 {
      border: $step-border-width solid $black;
      padding: 20px (20px - $step-border-width * 2);
      height: 30px;
      line-height: 30px;
      margin: 0 0 10px;
      position: relative;
      font-size: 28px;
      .number {
        background: $black;
        position: absolute;
        top: -$step-border-width;
        bottom: -$step-border-width;
        width: $step-height;
        left: -$step-height;
        color: $background-red;
        @include medium-type;
        font-size: 40px;
        $top-offset: 0px;
        padding: $top-offset 0 0;
        line-height: $step-height - $top-offset;
        text-align: center;
      }
    }
  }
}

@media screen and (min-width: 960px) {
  .steps {
    li {
      margin: 0 0 0 50%;
      text-align: center;
      padding: 0 0 0 ($step-height / 2 - $step-border-width);
      max-width: none;

      h5 {
        font-size: 32px;
      }
      p {
        margin: 0 40px;

      }
      &:after {
        content: '';
        width: $step-border-width;
        background: $black;
        position: absolute;
        top: $step-height;
        bottom: 0;
        margin: 0 -$step-border-width / 2;
        left: 0;
      }

      &:nth-child(2n + 1) {
        margin: 0 50% 0 0;
        padding: 0 ($step-height / 2 - $step-border-width) 0 0;
        .number {
          left: auto;
          right: -$step-height;
        }
        &:after {
          left: auto;
          right: 0;
        }
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .steps {
    li {
      h4 {
        font-size: 20px;
        line-height: 1;
        padding-top: 15px;
      }
      p {
        margin-left: -$step-height + 5px;
      }
    }
  }
}

.faq {
  background-color: $background-red;
  color: $black;
  padding: 0 30px;
  margin: 0;
  width: auto;
  display: inline-block;

  ul {
    width: 50%;
    list-style: none;
    margin: 60px 0 120px 0;
    padding: 0;
    li {
      margin: 0 4% 40px 0;
      font-size: 1.5em;

      h4 {
        margin: 0;
      }
    }
  }
  @media screen and (max-width: $mobile-max) {
    padding: 0;
    ul {
      @include product-column();
      float: none;
      margin-top: 0;

      li {
        font-size: 1.2em;
        margin-right: 0;
        margin-bottom: 1.2em;
      }
    }
  }
}

#about {
  padding: 30px 0;
  background: $bggrey;
  border-top: 15px #fff solid;
  text-align: center;
  margin: 0 0 20vw;
  color: #fff;
  h2 {
    text-align: center;
    margin: 0 0 4vw;

    @include vw_min_max(font-size, 12, 320px, 1200px);
  }
  .text {
    max-width: 640px;
    padding: 0 20px;
    margin: 0 auto;
  }
  .image {
    border: 10px #fff solid;
    margin: 0 10vw 0;
    width: auto;
    img {
      display: block;
      width: 100%;
      height: auto;
      margin: 0;
    }
  }
}

.order-body, .static-body {
  background: $background-red;
}

.order, .static {
  background: #fff;
  color: $faded-black;
  position: relative;

  /* @media screen and (max-width: 880px) {
      width: 400px;
      padding: 25px 4%;

  } */

  // width: 660px;
  padding: 60px 6%;
  margin: 100px auto;

  max-width: 660px;
  min-width: 260px;
  width: 75%;

  a {
    color: $faded-black;
    text-decoration: underline;
  }
  .title {
    position: absolute;
    bottom: 100%;
    left: 0;
    border: $border-width #fff solid;
    color: #fff;
    margin: 0 0 (-$border-width);
    padding: 15px 20px;
    min-width: 250px;
    text-align: center;
    font-size: 34px;
  }
}

.static {
  .text {
    color: $faded-black;
    font-size: 16px;
    h3, h4, h5 {
      @include bold-type;
      text-transform: none;
      margin: 0 0 10px;
    }
    h5 {
      font-size: 24px;
    }
  }
}

.order {
  opacity: 0;
  @include transition(300ms, opacity);

  // column widths - middle column derived
  $left: 34%;
  $right: 25%;
  // responsive breakpoint
  $breakpoint: 560px;

  .errorlist, .error {
    color: $background-red;
    font-size: 14px;
  }
  .errorlist {
    padding: 0;
    list-style: none;
    margin: 5px 0;
  }
  .error {
    margin: 0 0 20px;
  }

  section {
    @include clearfix;
    margin: 0;

    a, input[type="submit"], .select-mask {
      color: $background-red;
      &, select {
        cursor: pointer;
      }
      text-decoration: underline;
      background: none;
      border: none;
      padding: 0;
      font-size: 17px;
      &:hover {
        text-decoration: none;
      }
    }
    h5 {
      border-bottom: 1px $faded-black solid;
      padding: 0 0 5px;
      margin: 0 0 20px;
    }
    h6 {
      margin: 0 0 5px;
      font-size: 17px;
    }
    .row {
      @include clearfix;
      margin: 0 0 20px;
      padding: 0 0 20px;
      border-bottom: 1px $faded-black solid;
    }
    .model {
      float: left;
      width: $left - 2%;
      padding: 0 2% 0 0;
      font-size: 17px;

      @media screen and (max-width: $breakpoint) {
        float: none;
        width: auto;
        margin: 0;
        h6 {
          margin: 0;
        }
      }
    }

    .items {
      float: left;
      width: (100% - $left);
      font-size: 17px;
      $width: 100% * $right / (100% - $left);
      .item {
        margin: 0;
        position: relative;
        padding: 0 ($width + 1%) 5px 0;
        .price {
          position: absolute;
          right: 0;
          top: 0;
          width: $width;
        }
        .service.has-service {
          select {
            width: 95%;
          }
          .select-mask {
            color: $faded-black;
            text-decoration: none;
            &:hover {

            }
          }
        }
        .delete input[type="submit"] {
          position: absolute;
          right: 0px;
          top: 0;
          visibility: hidden;
        }
        &:hover .delete input[type="submit"] {
          visibility: visible;
        }
      }

      @media screen and (max-width: $breakpoint) {
        width: auto;
        float: none;
        margin-bottom: 10px;
      }

      .add {
        .select-mask {
          position: relative;
          &:before {
            content: '+';
            position: absolute;
            left: -11px;
            top: 0;
          }
        }
      }
    }
    .extra {
      font-size: 17px;
      padding: 5px 0 0;
      clear: both;
    }
    .screen-protector {

      .checkbox-mask {
        border-color: $faded-black;
        height: 12px;
        width: 12px;
        top: 2px;

        &.checked {
          background: $faded-black;
        }
      }
    }
    .fineprint {
      font-size: 12px;
    }
    .add-model {
      margin: 10px 0 0;
    }

    &.service {
      margin: 0 0 40px;

      .notes {
        margin: 40px 0;
        label {
          display: block;
          margin: 0 0 10px;
        }
        textarea {
          height: 80px;
          width: 414px;
        }
        .help {
          color: $textgrey;
          font-size: 0.8em;
          margin: 5px 0;
        }
      }
      @media screen and (max-width: $breakpoint) {
        .notes textarea {
          width: 240px;
        }
      }
    }
    &.delivery {
      margin: 0 0 20px;
      .items {
        margin-bottom: 20px;
      }
      .items, .fineprint {
        margin-left: $left;
      }

      @media screen and (max-width: $breakpoint) {
        .items {
          margin-left: 0;
        }
      }
    }

    .form {
      margin: 0 0 0 -30px;
      .form-field {
        display: inline-block;
        margin: 0 0 10px 30px;
        input {
          width: 238px;
        }
        label {
          display: none;
        }
      }

    }

    &.address {
      margin: 0 0 20px;
      &:before {
        content: '';
        border-top: 1px $faded-black solid;
        display: block;
        margin: 10px 0 20px;
      }
      h5 {
        padding-bottom: 10px;
        margin-bottom: 15px;
      }

      .workshop-details {
        padding-left: 30px;
        @include regular-type;
        font-size: 12px;
        color: #999;
        top: -10px;
        position: relative;
      }

      .form {
        vertical-align: top;
        display: inline-block;
        width: 260px;
        margin: 0 0 20px;

        &.first {
          margin-right: 80px;
        }

        .radio {
          font-size: 12px;
          padding-left: 30px;
          position: relative;
          display: block;
          @include regular-type;
          margin: 10px 0 15px;
          .radio-mask {
            position: absolute;
            left: 0;
            top: 0;
          }
        }

        .form-field {
          margin-left: 0;
          display: block;
        }

        .fields {
          padding: 0 0 30px;

          .edit {
            color: $faded-black;
            text-decoration: underline;
            @include regular-type;
            font-size: 12px;
            margin: 10px 0 0;
            display: block;
            cursor: pointer;
          }
          &.disabled {
            .form-field {
              margin: 0;
              position: relative;

              .errorlist, .help {
                display: none;
              }
              &:after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
              }
            }
            input {
              border: none;
              padding: 0;
            }
          }
        }

      }
    }

    &.comments {
      border-top: 1px solid $faded-black;
      padding: 20px 0;
      label {
        font-size: 14px;
        margin: 0 0 10px;
        display: block;

        &.toggle {
          text-decoration: underline;
          cursor: pointer;
        }
      }
      textarea {
        height: 60px;
        min-width: 238px;
        max-width: 338px;
        width: 80%;
      }
    }

    &.thanks {
      margin: 0 0 50px;
    }
  }

  .total {
    font-size: 28px;
    padding: 20px 0 0px (100% - $right);
    border-top: 1px $faded-black solid;
    text-transform: uppercase;
    margin: 0;
    position: relative;

    span {
      position: absolute;
      right: 25%;
      margin-right: 10px;
      top: 20px;
    }

    @media screen and (max-width: $breakpoint) {
      padding-left: 0;
      span {
        position: static;
      }
    }
  }

  .next {
    box-sizing: content-box;
    position: absolute;
    text-decoration: none;
    right: 0;
    top: 100%;
    background: #fff;
    color: $background-red;
    padding: 15px 20px;
    min-width: 120px;
    text-align: center;
    font-size: 34px;
    border: none;
    @include bold-type;
    text-transform: uppercase;
    margin-top: 2px;
    &:hover {
      color: $mediumred;
    }
  }
  .prev {
    position: absolute;
    left: 40px;
    top: 20px;
    font-size: 14px;
    @include regular-type;
    line-height: 20px;
    &:before {
      content: 'â ';
      text-decoration: none;
      position: absolute;
      left: -30px;
      top: 0;
      font-size: 20px;
      line-height: 20px;
    }
    &:hover {
      text-decoration: none;
    }
  }

  &.confirm {
    .info {
      span {
        display: inline-block;
        margin-right: 40px;
      }
    }
    .address {
      &:before {
        margin-bottom: 40px;
      }
      .option {
        @include regular-type;
        font-size: 12px;
        margin: 0 0 10px;
      }
    }

    .terms {
      margin: 20px 0 0;
      display: block;
      font-size: 17px;
      a {
        color: $faded-black;
        text-decoration: underline;
      }
      .checkbox-mask {
        @include checkbox-mask($faded-black, 14px);
        margin-right: 10px;
        top: 4px;
      }

      @media screen and (max-width: $breakpoint) {
        font-size: 17px;
      }
    }
  }
}

.catalogue-nav, .shop-pages {
  margin-left: $col-width / 2;
  ul {
    @include medium-type();

    padding: 0;
    margin: 1.5em 0;
    font-size: 16px;

    li {
      list-style-type: none;
      display: inline-block;
      margin-right: 18px;
      color: white;

      a {
        color: white;
        &:hover, &.current {
          color: $red-type;
        }
      }

      &:first-child {
        color: $red-type;
      }
    }
  }
}

div#content {
  > div.wrapper, div.red > div.wrapper {
    margin: 0 auto;
    max-width: $max-fluid;
  }

  > div.red {
    background-color: $background-red;
    width: 100%;
  }

  .sub-menu {
    @extend .noselect;
    display: inline-block;
    width: 100%;
    background-color: $background-red;
    position: relative;
    font-size: 2em;

    @media screen and (max-width: $max-fluid) {
      font-size: 3vw;
    }

    .catalogue-nav {
      position: absolute;
      bottom: 100%;
      left: 0;
      right: 0;

    }

    nav {
      margin: 40px ($col-width / 2);
      font-size: 18px;

      ul {
        @extend .clearfix;

        padding: 0;
        margin: 0;

        li {
          display: inline-block;
          width: 25%;
          float: left;
          font-size: 1em;
          line-height: 1em;
          height: 180px;
          border-right: 1px solid black;
          box-sizing: border-box;

          &:first-child {
            border-left: 1px solid black;
          }
          h4 {
            font-size: 32px;
            line-height: 32px;
            font-weight: normal;
            margin: 0 0 35px;
            p {
              line-height: 1;
            }
          }
          p {
            display: inline-block;
            margin: 0;
            line-height: 1.2;
          }
          a {
            box-sizing: border-box;
            display: block;
            height: 100%;
            color: $black;
            position: relative;
            z-index: 1;
            &:hover, &.current {
              color: white;
            }
          }

          a, &.no-link p {
            padding-left: 7%;
            padding-right: 7%;
          }

        }
      }
    }
    .submenu-footer {
      margin-left: 30px;
      margin-top: 80px;
      font-size: 0.6em;
      @include regular-type();
    }

    @media screen and (max-width: $mobile-max) {
      display: none;
    }
  }

  .point-checklist {
    width: 100%;
    display: block;

    ol {
      padding: 0;
      -moz-column-count: 2;
      -webkit-column-count: 2;
      column-count: 2;

      -moz-column-gap: 0;
      -webkit-column-gap: 0;
      column-gap: 0;

      @media screen and (max-width: $mobile-max) {
        -moz-column-count: 1;
        -webkit-column-count: 1;
        column-count: 1;
      }

      li {
        @include list-style();
        position: relative;
        counter-increment: customlistcounter;
        $margin-offset: 35px;
        $approximate-number-width: 10px;
        margin-left: $margin-offset;
        margin-right: $margin-offset - $approximate-number-width;
        color: black;

        display: block;

        // prevent li from being split over two columns
        page-break-inside: avoid; /* For Firefox. */
        -webkit-column-break-inside: avoid; /* For Chrome & friends. */
        break-inside: avoid;

        &:before {
          content: counter(customlistcounter, decimal-leading-zero) " ";
          display: block;
          position: absolute;
          left: -$margin-offset;
          font-size: 0.9em;
        }

      }
    }
  }
}

.tree {
  $padding-top: 100px;
  @mixin line($length, $horizontal: 0) {
    $width: $border-width;
    content: '';
    position: absolute;
    background: $tree-line;
    @if $horizontal == 0 {
      width: $width;
      height: $length;
      margin-left: -$width / 2;
    } @else {
      height: $width;
      width: $length;
      margin-top: -$width / 2;
    }
  }

  // STYLING

  list-style: none;
  padding: $padding-top 0 50px;
  position: relative;
  text-align: center;
  z-index: 1;
  margin: 0 auto;
  overflow: hidden;

  ul {
    list-style: none;
    margin: 0;
  }
  li {
    margin: 0;
    padding: 0;
    display: inline-block;

    .label, label {
      border: 4px $tree-line solid;
      display: block;
      color: $tree-line;
      cursor: pointer;
    }

    &.hovered {
      > .label, > label {
        background: $tree-hover-background;
        color: $tree-hover-text;
      }
    }
    &.active {
      > .label, > label {
        border-color: $tree-line;
        color: $tree-selected-text;
        background: $tree-selected-background;
      }
    }
  }
  input {
    display: none;
  }

  // LAYOUT

  @media screen and (min-width: 960px) {
    $box-height: 110px;
    li {
      position: relative;
      .label, label {
        padding: 20px;
        height: $box-height - $border-width * 2 - 40px;
        line-height: $box-height - $border-width * 2 - 40px;
        font-size: 40px;
      }
    }

    // manufacturers
    > li {
      $margin: 40px;
      min-width: 230px;
      margin: 0 $margin;
      &:before {
        @include line($margin * 2, 1);
        top: 50%;
        left: 100%;
        //margin-left: $border-width;
      }
      &:last-child:before {
        display: none;
      }
      &:first-child:after {
        @include line($box-height / 2 + $padding-top);
        top: -$padding-top;
        left: 100%;
        margin-left: $margin;
      }
    }
    // products
    > li > ul {
      $padding-top: 70px;
      position: absolute;
      top: 100%;
      left: 50%;
      width: 780px;
      padding: $padding-top 0 0;
      margin: 0 0 0 -135px;

      &:after {
        @include line($padding-top);
        top: 0;
        left: 120px;
        //margin-left: -$border-width / 2;
      }
      > li {
        $margin: 20px;
        margin: 0 $margin;
        &:before {
          @include line($margin * 2, 1);
          top: 50%;
          left: 100%;
          //margin-left: $border-width;
        }
        &:last-child:before {
          display: none;
        }
      }
    }

    // models
    > li > ul > li > ul {
      $padding-top: 40px;
      position: absolute;
      top: 100%;
      left: 0;
      width: 600px;
      padding: $padding-top 0 0;
      margin: 0;
      text-align: left;

      &:after {
        @include line($padding-top);
        top: 0;
        left: 30px;
        margin-left: -$border-width / 2;
      }

      li {
        margin: 0 10px 10px 0;
        label {
          min-width: 60px;
          text-align: center;
          display: block;
          font-size: 36px;
        }
      }
    }
    > li:nth-child(2) > ul {
      margin-left: -390px;
      &:after {
        left: 49%;
      }
    }
    > li:last-child > ul {
      left: auto;
      right: -20px;
      margin-left: 0;
      text-align: right;

      &:after {
        left: auto;
        right: 80px;
      }
      > li > ul {
        right: 0;
        left: auto;
        text-align: right;
        &:after {
          left: auto;
          right: 30px;
          margin-right: -$border-width / 2;
        }
      }
    }
    // special case for mac since the names are way longer
    > li:first-child > ul > li:last-child > ul {
      width: 740px;
      right: 0;
      left: auto;
      text-align: right;
      &:after {
        left: auto;
        right: 30px;
        margin-right: -$border-width / 2;
      }
      li label {
        font-size: 20px;
      }
    }
  }
  @media screen and (max-width: 959px) {
    $box-height: 60px;
    padding: 0 $col-width 50px;
    max-width: 600px;
    min-width: $min-width - $side-padding * 2 - 10px;

    li {
      .label, label {
        padding: 0 5px;
        height: $box-height - $border-width * 2;
        line-height: 1;
        font-size: 20px;
        text-align: center;
      }
      label {
        line-height: $box-height - $border-width * 2;
      }
      .label > span {
        height: 100%;
        width: 100%;
        display: table;
        span {
          display: table-cell;
          vertical-align: middle;
        }
      }
    }

    // manufacturers
    > li {
      $margin: 30px;
      min-width: 30%;
      margin: $margin 0 0 5%;

      > .label {
        position: relative;
        display: block;
        &:before {
          @include line(15%, 1);
          top: 50%;
          right: 100%;
          margin-right: $border-width;
          padding-left: $border-width;
        }
      }
      &:first-child {
        margin-left: 0;
        > .label {
          &:before {
            display: none;
          }
          /* &:after {
              @include line($box-height / 2 + $margin);
              top: -$margin - $border-width;
              left: 125%;
              margin-left: $border-width;
          } */
        }
      }
    }

    // products
    > li > ul {
      $margin: 30px;
      position: absolute;
      left: 0;
      right: 0;
      padding: 0 $col-width;

      /* &:before {
          @include line($box-height / 2 + $margin);
          top: -$margin;
          left: 50%;
          margin-left: -$border-width / 2;
      } */

      > li {
        width: 21%;
        margin: $margin 0 0 5%;

        > .label {
          position: relative;
          display: block;

          &:before {
            @include line(22%, 1);
            top: 50%;
            right: 100%;
            margin-right: $border-width;
            padding-left: $border-width;
          }

        }

        &:first-child {
          margin-left: 0;
          > .label {
            &:before {
              display: none;
            }
          }
        }
      }
    }

    // models
    > li > ul > li > ul {
      $margin: 30px;
      $gap: 10px;
      position: absolute;
      left: 0;
      right: 0;
      padding: $margin $col-width 0;
      text-align: left;
      margin-left: -$gap;

      > li {
        min-width: $box-height;
        margin: 0 0 $gap $gap;
      }
    }
  }

  @media screen and (max-width: 600px) {
    li .label, li label {
      font-size: 12px;
    }
  }
  // @media screen and (max-width: $min-width + 10px) {
  //     padding-left: 0;
  //     padding-right: 0;
  //     margin-left: auto;
  //     margin-right: auto;
  //     width: $min-width - $side-padding * 2;
  // }

  // INTERACTION

  @mixin hidden {
    visibility: hidden;
    z-index: 0;
    // display: none;
  }
  @mixin visible {
    visibility: visible;
    z-index: 2;
    // display: block;
  }

  @media screen and (max-width: 959px) {
    // for small screen layout, don't show default (this is just a clone of
    // the below with references to the default els removed

    // show hovered el
    > li.hovered > ul {
      @include visible;
      > li.hovered > ul {
        @include visible;
      }
    }

    // hide all els
    > li > ul,
      // hide active when parent not active
    > li.active > ul {
      @include hidden;
      > li > ul,
      > li.active > ul {
        @include hidden;
      }
    }

    // show hovered el
    > li.hovered > ul {
      @include visible;
      > li.hovered > ul {
        @include visible;
      }
    }
  }
  @media screen and (min-width: 960px) {
    // hide all els
    > li > ul,
      // hide default when hovered
    &.hovered > li:first-child > ul,
    > li.hovered > ul.hovered > li.default > ul,
      // hide active when parent not active
    > li.active > ul,
      // hide default when parent is active
    &.active > li.default > ul {
      @include hidden;
      > li > ul,
      &.hovered > li:nth-child(2) > ul,
      > li.active > ul,
      > li.default > ul {
        @include hidden;
      }
    }

    // show default el
    > li.default > ul,
      // show hovered el
    > li.hovered > ul,
      // override above rules hiding default
    > li.hovered.default > ul {
      @include visible;
      > li.default > ul,
      > li.hovered > ul,
      > li.hovered.default > ul {
        @include visible;
      }
    }
  }

  // show active els, when parent is active
  &.active > li.active > ul {
    @include visible;
    > li.active > ul {
      @include visible;
    }
  }

}

body.store-checkout div.order-wrapper div.container article.account {
  padding: 40px 0;
  h2 {
    margin: 0 0 10px;
    font-size: 40px;
  }
  h6 {
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
  }
  h4 {
    font-size: 20px;
  }

  ul.messages {
    display: block;
    margin: 0 0 20px;
    li {
      float: none;
    }
  }

  > nav {
    border-top: 2px solid $black;
    border-bottom: 1px solid $black;
    margin: 0 0 30px;

    a, > span {
      padding: 10px 0;
      margin-right: 5px;
    }
    > span {
      display: none;
    }
    a {
      display: inline-block;
    }
    a, &:hover a.current {
      color: $black;
    }
    a:hover, a.current, &:hover a.current:hover {
      color: $red-type;
    }
    @media (min-width: 600px) {
      a, > span {
        margin-right: 20px;
      }
      span {
        display: inline-block;
      }
    }
  }

  .account-orders {
    ul, ul li {
      display: block;
      float: none;
    }
    > ul {
      > li {
        border-bottom: 1px solid $black;
        padding: 25px 0;
        @include clearfix;
        h6 {
          margin: 0 0 10px;
        }
        h4, .order-status, ul li .line-total, .order-total {
          margin: 0 0 10px;
          font-size: 20px;
          @include medium-type;
        }
        ul {
          padding: 0px;
        }
        ul li {
          max-width: 400px;
          @include clearfix;
          padding: 0 0 5px;
          > span {
            display: inline-block;
            margin-right: 20px;
          }
          > div {
            float: left;
            min-width: 225px;
          }
          p {
            font-size: 0.8em;
            clear: both;
          }
          h4, p {
            margin: 0;
          }
          .line-total {
            color: $red-type;
            float: left;
          }
        }
      }
    }
    .order-status {
      float: left;
      width: 50%;
    }
    .order-total {
      float: right;
      min-width: 200px;
      color: $red-type;
    }
  }

  .wishlist {
    ul {
      li {
        display: inline-block;
        float: none;
        width: 45%;
        min-width: 250px;
        // margin-bottom: 5%;
        padding: 20px 0 10px;
        border-bottom: 1px solid $black;

        &:last-child, &:nth-last-child(2):nth-child(2n+1) {
          border-bottom: none;
        }
        &:nth-child(2n+1) {
          margin-right: 10%;
        }

        h4 {
          margin: 0 0 10px;
          .price {
            float: right;
            color: $red-type;
          }
        }
        img {
          display: block;
          width: 100%;
          height: auto;
        }
        .detail {
          padding: 15px 0;
          font-size: 0.8em;
          div {
            display: inline-block;
          }
          .title {
            margin-right: 15px;
          }
        }
        nav {
          a, input[type="submit"] {
            font-size: 1.1em;
            margin: 0 0 5px;
            color: $red-type;
            display: block;
            @include medium-type;
            background: none;
            display: block;
            padding: 0;
            width: auto;
            max-width: none;
          }
        }
      }
    }
    nav.share {
      border-top: 1px solid $black;
      padding: 15px 0;
      a {
        display: inline-block;
        margin: 0 15px 0 0;
      }
    }
  }
}


// hack a gap beside the email field, so that the password fields will be side-by-side
body.store-checkout div.order-wrapper div.container form.account-details-form .form-field.email {
  margin-right: 50%;
}

body.home {
  font-size: 1vw;

  nav {
    @extend .clearfix;
    width: auto;
    $border-padding: 15px;
    padding-top: $border-padding;
    padding-bottom: $border-padding;
    margin-left: $border-padding;
    margin-right: $border-padding;
    box-sizing: border-box;
    position: relative;

    //height: 100vh;

    &.mobile-nav {
      display: none;
    }

    > div.links {
      display: inline;
    }
    $padding-left: 5%;

    a {
      color: white;

      box-sizing: border-box;
      float: left;
      display: block;

      width: 50%;

      &:first-child {
        div.wrapper {
          padding-left: calc(#{$padding-left} - #{$border-padding});
        }

        //background-color: red;
      }
      &:last-child {
        div.wrapper {
          padding-left: $padding-left;
        }

        //background-color: blue;
      }

      div.wrapper {
        width: 100%;
        position: relative;
        box-sizing: border-box;
        min-height: calc(100vh - 2 * #{$border-padding});
        display: inline-block;
        @include transition(100ms, background-color, linear);

        .container {
          //@include vertical-align();
          width: 100%;
          display: inline-block;

          h1 {
            margin-top: 1.5em;
          }

          p {
            font-size: 2em;
            margin-bottom: 0.2em;
          }

          > p {
            min-height: 3em;
            margin-top: 1.5em;
            margin-bottom: 1em;
          }

          .enter-site {
            @include medium-type();
            margin-bottom: 20em;
            display: inline-block;
          }
        }

        .address {
          position: absolute;
          font-size: 1.2em;
          bottom: 1em;
          left: calc(#{$padding-left} - #{$border-padding});
          white-space: nowrap;
          //color: #FFF;
        }
      }

      @media screen and (min-width: $mobile-max) {
        &.hover {
          color: black;
          background-color: $black;

          div.wrapper {
            background-color: $background-red;
            div.container {
              .enter-site:hover {
                color: white;
              }
            }
          }

          &#store div.wrapper {
            background-color: $bile-blue;
          }
        }
      }

    }
  }

  #background-phone {
    position: absolute;
    width: 7 * $col-width;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: -1;
    top: 6em;
    @include transition(1s, all, linear);

    img {
      width: 100%;
      height: auto;

    }

    &.opacity {
      opacity: 0.5;
    }
  }

  @media screen and (max-width: $mobile-max) {
    nav {

      &.landing-nav {
        display: none;
      }

      &.mobile-nav {
        display: block;
      }
    }

    #background-phone {
      display: none;
    }

  }
}

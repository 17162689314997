footer#footer {
  @extend .clearfix;
  color: $black;
  display: inline-block;
  width: 100%;
  padding-bottom: 80px;
  $top-padding: 40px;

  nav {
    float: left;
    display: inline-block;
    padding-left: 15px;
    text-transform: uppercase;
    width: 30%;
    box-sizing: border-box;

    ul {
      padding: 0;
      margin-top: $top-padding;

      li {
        margin-right: 20px;
        display: inline-block;
        a {
          @include nav-type();
          color: $black;

          &:hover, &.current {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .details {
    @extend .clearfix;
    float: left;
    width: 70%;
    box-sizing: border-box;
    padding-right: 15px;
    margin-top: $top-padding;
    font-size: 0.85em;
    line-height: 1.2em;

    .address {
      width: 33%;
      float: left;
      box-sizing: border-box;
      padding-right: 10px;

      p {
        margin-bottom: 0;
      }
    }

    .contact {
      @extend .address;

      .social {
        text-align: left;
        margin: 0;
        a {
          $social-size: 26px;
          background: $black center center no-repeat;
          font-size: 1px;
          width: $social-size;
          padding-top: $social-size;
          @include rounded($social-size / 2);
          height: 0;
          overflow: hidden;
          display: inline-block;
          background-size: ($social-size * 0.9) ($social-size * 0.9);
          margin: 0 15px 0 0;

          &:hover {
            background-color: $slightlydarkred;
          }
        }
        .f {
          background-image: url(../i/social/facebook.png);
        }
        .t {
          background-image: url(../i/social/twitter.png);
        }
        .i {
          background-image: url(../i/social/instagram.png);
        }
      }

      .methods {
        a {
          border-bottom: none;
        }
      }
    }
  }

  @media screen and (max-width: $mobile-max) {
    nav {
      display: none;
    }

    .details {
      width: $col-width * ($total-cols - 2);
      margin-left: $col-width;
    }
    .address {
      width: 50%;
      margin-bottom: 20px;
    }
    .contact {
      width: 100%;
    }
  }
}

body.catalogue-site {
  footer#footer nav ul li a:hover, footer#footer nav ul li a.current {

  }

  footer#footer {
    nav {
      ul {
        li {
          a {
            &.current, &:hover {
              color: $background-red;
            }
          }
        }
      }
    }
  }
}

@mixin grid($cols: 0, $gutter: 5px) {
  float: left;
  display: inline;
  margin-left: $gutter;
  margin-right: $gutter;
  @if $cols {
    width: $cols * 40px - $gutter * 2;
  }
}

@mixin left($cols: 1) {
  margin-left: (30px * $cols) + (10px * ($cols - 1)) + 15px;
}

@mixin right($cols: 1) {
  margin-right: (30px * $cols) + (10px * ($cols - 1)) + 15px;
}

@mixin rotated($deg, $origin-left: center, $origin-top: center) {
  -moz-transform: rotate($deg);
  -webkit-transform: rotate($deg);
  -ms-transform: rotate($deg);
  transform: rotate($deg);

  -moz-transform-origin: $origin-left $origin-top;
  -webkit-transform-origin: $origin-left $origin-top;
  -ms-transform-origin: $origin-left $origin-top;
  transform-origin: $origin-left $origin-top;
}

@mixin rounded($radius) {
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
  border-radius: $radius;
}

@mixin transition($duration, $property: all, $type: ease-in-out) {
  -moz-transition-property: $property;
  -moz-transition-duration: $duration;
  -moz-transition-timing-function: $type;
  -webkit-transition-property: $property;
  -webkit-transition-duration: $duration;
  -webkit-transition-timing-function: $type;
  transition-property: $property;
  transition-duration: $duration;
  transition-timing-function: $type;
}

@mixin greyscale($amount) {
  filter: grayscale($amount * 100%);
  -webkit-filter: grayscale($amount * 100%);
  -moz-filter: grayscale($amount * 100%);
  -ms-filter: grayscale($amount * 100%);
  -o-filter: grayscale($amount * 100%);
  @if $amount {
    filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
    filter: gray;
  }
  else {
    filter: none;
  }
}

@mixin animation($val) {
  -o-animation: $val;
  -moz-animation: $val;
  -webkit-animation: $val;
  animation: $val;
}

//@mixin keyframes($name) {
//  @-o-keyframes $name {
//    @content;
//  }
//  @-moz-keyframes $name {
//    @content;
//  }
//  @-webkit-keyframes $name {
//    @content;
//  }
//  @keyframes $name {
//    @content;
//  }
//}

@mixin scale($amount) {
  -moz-transform: scale($amount);
  -webkit-transform: scale($amount);
  -o-transform: scale($amount);
  -ms-transform: scale($amount);
  transform: scale($amount);
}

@mixin box-sizing($val) {
  -moz-box-sizing: $val;
  -webkit-box-sizing: $val;
  box-sizing: $val;
}

@mixin clearfix {
  /* For modern browsers */
  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  /* For IE 6/7 (trigger hasLayout) */
  & {
    zoom: 1;
  }
}

.clearfix {
  @include clearfix();
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.undraggable {
  -webkit-user-drag: none; /* Prevents dragging of images/divs etc */
  user-drag: none;
}

@mixin placeholder($color, $font-size: 1em) {
  /* This could be made much better with sass 3.2, see
     http://sass-lang.com/docs/yardoc/file.SASS_REFERENCE.html#mixins */
  &.placeholder {
    color: $color;
    font-size: $font-size;
  }
  &:-moz-placeholder {
    color: $color;
    font-size: $font-size;
  }
  &:-ms-placeholder {
    color: $color;
    font-size: $font-size;
  }
  &::-webkit-input-placeholder {
    color: $color;
    font-size: $font-size;
  }
}

@mixin vw_min_max($prop, $size, $min-width, $max-width) {
  /* semicolons so sass doesn't think # denotes a comment... */
;
  #{$prop}: #{$size + 'vw'};
  @media screen and (max-width: $min-width) {;
    #{$prop}: $min-width * $size / 100;
  }
  @media screen and (min-width: $max-width) {;
    #{$prop}: $max-width * $size / 100;
  }
}

@mixin transform($val) {
  -moz-transform: $val;
  -webkit-transform: $val;
  -ms-transform: $val;
  transform: $val;
}

@mixin vertical-align {
  position: absolute;
  top: 50%;
  @include transform(translateY(-50%));
}

@mixin select-wrapper() {
  @include regular-type();
  position: relative;
  display: block;
  z-index: 1;

  &::after {
    content: "â¼";
    position: absolute;
    right: 5px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: -1;
    cursor: pointer;
    font-size: 10px;
  }

  select {
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    -moz-border-radius: 0;
    background: none;
    cursor: pointer;

    color: black;
    padding: 0.8em 0;

    border: none;
    border-top: 1px solid $black;
    border-bottom: 3px solid $black;

    &:focus {
      outline: none;
    }
  }
}

@mixin product-column {
  float: left;
  //width: 33%;
  box-sizing: border-box;

  margin-left: $col-width / 2;
  width: 7.3333333 * $col-width;
  display: block;

  .wrapper {
    width: auto;
  }

  @media screen and (max-width: $mobile-max) {
    margin-left: $col-width;
    width: $col-width * ($total-cols - 2);
  }
}

@mixin list-style {
  font-size: 1em;
  padding: 0.25em 0;
  border-top: 1px solid $textgrey;
  list-style-type: none;

  .wrapper {
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  &:last-child, &:nth-child(15) {
    border-bottom: 1px solid $textgrey;
  }

  &:first-child, &:nth-child(16) {
    border-top-width: 2px;
  }
}

.product-column {
  @include product-column();
}

.product-layout {
  @extend .product-column;
  @extend .clearfix;
  margin-top: 3em;
  margin-bottom: 2em;
  border-top: 4px solid $black;

  .head {
    $head-padding: 0.8em;
    position: relative;
    padding: $head-padding 0;
    font-size: 1.1em;
    @include medium-type();
    .model {
      display: inline-block;
    }
    .price {
      display: inline-block;
      position: absolute;
      right: $head-padding;
      top: $head-padding;
      color: $price-red;
    }
  }

  .image {
    position: relative;
    background-color: black;
    padding-top: 100%;

    img {
      width: 100%;
      height: auto;
      opacity: 1;
      @include transition(0.1s, opacity, linear);
      @extend .undraggable;
      position: absolute;
      top: 0;
      left: 0;

      // Fix flicker when opacity changes on hover
      @include transform(translate3d(0, 0, 0));
      background-color: black;
    }

    .rating {
      @include regular-type();
      $circle-radius: 0.8em;
      font-size: 1.5em;
      position: absolute;
      box-sizing: border-box;
      border: 2px solid currentColor;

      width: $circle-radius * 2;
      height: $circle-radius * 2;
      line-height: calc(#{$circle-radius * 2} - 6px);

      border-radius: $circle-radius;
      text-align: center;

      color: $price-red;
      bottom: 4%;
      right: 4%;
    }
  }

  .detail {
    font-size: 15px;
    line-height: 1;
    margin-top: 1em;
    .title {
      display: inline-block;
      width: 25%;
      padding-right: 2px;
      min-height: 2em;
    }
    .sold {
      display: inline-block;
      vertical-align: top;

    }
    .shipping {
      display: inline-block;
      vertical-align: top;
      min-height: 2em;

    }
  }

  .psuedo-link {
    font-size: 15px;
    color: $price-red;
    margin-top: 5px;
    margin-bottom: 2px;
  }

  &:hover {
    .image img {
      opacity: 0.8;
    }
  }

  @media screen and (max-width: $mobile-max) {
    .head {
      //$head-padding: 0.8em;
      //position: relative;
      //padding: $head-padding 0;
      font-size: 1.5em;
      .model {
        //@include medium-type();
        //display: inline-block;
      }
      .price {
        //display: inline-block;
        //position: absolute;
        //right: $head-padding;
        //top: $head-padding;
        //color: $price-red;
      }
    }

    .detail {
      font-size: 1em;
      //line-height: 1;
      margin-top: 1em;
      .title {
        //display: inline-block;
        //width: 25%;
        //padding-right: 2px;
        //min-height: 2em;
      }
      .sold {
        //display: inline-block;
        //vertical-align: top;

      }
      .shipping {
        //display: inline-block;
        //vertical-align: top;
        //min-height: 2em;

      }
    }

    .psuedo-link {
      font-size: 1em;
    }
  }
}

@mixin bleed($color) {
  &:before, &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    background: $color;
    width: calc(50vw - 600px);
  }
  &:before {
    right: 100%;
  }
  &:after {
    left: 100%;
  }
}

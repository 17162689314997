// $red: #FB0D21; // looks right in chrome

//$red: #Fd3131;
// looks right in safari
$lightred: #FF383E;
$slightlydarkred: #CD201F;
$mediumred: #9F0317;
$darkred: #66010B;
$background-red: #fe0000;
$red-type: #FF0000;
$background-white: #fff;

$price-red: $red-type;

$header-colour: #FFF;
$faded-black: #333;
$black: #000;
$bggrey: #5f5f5f;
$textgrey: #928E8D;
$get-real-with-grey: #d7d7d7;
$background-grey: #f8f8f8;
$white: #fff;
$green: green;

$bile-blue: #ACC5C2;

$min-width: 320px;
$side-padding: 40px;
$border-width: 4px;

$mobile-max: 650px;
$tablet-max: 1000px;
$max-fluid: 1200px;
$total-cols: 24;
$col-width: 100% / $total-cols;
$default-gutter: 0;

$btn-green: #3a9a53;

// Colours for the repair tree
$tree-line: $black;
$tree-text: $black;
$tree-hover-background: $black;
$tree-hover-text: $background-red;
$tree-selected-background: $black;
$tree-selected-text: white;

$services-colour: black;
$services-select-border: black;

$book-order-text: black;
$book-order-border: black;



@mixin radio-mask {
  display: inline-block;
  border: none;
  width: 14px;
  height: 14px;
  @include rounded(7px);
  background: #D5D5D5;
  &.checked {
    background: $faded-black;
  }
}

@mixin checkbox-mask($color: $services-select-border, $size: 22px) {
  display: inline-block;
  border: 2px $color solid;
  width: $size;
  height: $size;
  @include rounded(0);
  background: none;
  &.checked {
    background: $color;
  }
}

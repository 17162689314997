body.cms-page {
  .sub-menu {

    .submenu-footer {
      display: none;
    }
  }

  .about-content {
    @extend .clearfix;
    position: relative;
    z-index: 1;

    $margin-top: 30px;

    .first-column {
      margin-top: $margin-top;
      box-sizing: border-box;
      float: left;
      width: 50%;
      padding-left: $col-width * 1.1;
      padding-right: $col-width * 1.5;
      font-size: 28px;

      .image {
        margin-top: 40px;
        margin-bottom: 120px;
        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .second-column {
      float: left;
      width: 25%;
      box-sizing: border-box;
      margin-top: $margin-top;
      padding-left: 3%;
      padding-right: 3%;
      font-size: 20px;
    }

    .background-divider {
      @extend .clearfix;
      position: absolute;
      left: $col-width / 2;
      right: $col-width / 2;
      top: $margin-top;
      height: 150px;
      box-sizing: border-box;
      padding: 0;
      margin: 0;
      z-index: -1;

      li {
        list-style-type: none;
        box-sizing: border-box;
        width: 25%;
        height: 100%;
        float: left;
        border-left: 2px solid currentColor;

        &:nth-child(2) {
          border: none;
        }

        &:last-child {
          border-right: 2px solid currentColor;
        }
      }
    }

    @media screen and (max-width: $mobile-max) {
      .first-column, .second-column {
        float: none;
        width: auto;
      }

      .background-divider {
        display: none;
      }
    }
  }

  &.warranty-page {
    .warranty-description {
      margin-left: $col-width / 2;
      width: 50%;
      margin-top: 60px;
      margin-bottom: 80px;
      font-size: 1.5em;

      h4 {
        margin-bottom: 0;
      }

    }
  }

  &.terms-page {
    #content {
      .terms-description {
        margin: 60px 0 80px;
        padding: 0 30px;
        font-size: 1.5em;

        h4 {
          margin-bottom: 0;
        }

        p {
          width: 50%;
        }
      }
    }
  }

  &.shipping-page {
    .shipping-description {
      margin-left: $col-width / 2;
      width: 50%;
      margin-top: 60px;
      margin-bottom: 80px;
      font-size: 1.5em;

      h4 {
        margin-bottom: 0;
      }
    }
  }

  @media screen and (max-width: $mobile-max) {
    &.shipping-page .shipping-description,
    &.terms-page .terms-description,
    &.warranty-page .warranty-description {
      @include product-column();
      float: none;
      font-size: 1.2em;
      margin-top: 2em;
    }
  }

  &.thirty-point-check {
    #content {
      background-color: white;

      .head {
        @extend .clearfix;
        width: 100%;
        position: relative;

        .title {
          float: right;
          margin-right: $col-width / 2;
          width: 15.16666666 * $col-width;
          margin-bottom: 4em;

          h1 {
            margin-top: 40px;
            font-size: 9vw;
            line-height: 0.8em;
            padding-right: 10px;
            border-bottom: 8px solid currentColor;
            padding-bottom: 10px;
            margin-bottom: 20px;
            @include medium-type();

            @media screen and(min-width: $max-fluid) {
              font-size: 6em;
            }
          }

        }
        .annotation {
          width: 5 * $col-width;
          position: absolute;
          left: $col-width / 2;
          bottom: 0;
          font-size: 1.5em;
          color: $red-type;

          @include medium-type();

          p {
            line-height: 1em;
          }
        }
      }

      .checklist {
        @extend .clearfix;
        width: 100%;

        .image-wrapper {
          float: left;
          box-sizing: border-box;
          border-top: 4px solid currentColor;

          margin-top: 25px;
          margin-left: $col-width / 2;
          width: 7.3333333 * $col-width;
          display: block;
          min-height: 10px;
          text-align: right;

          .image {
            display: inline-block;
            margin-top: 45px;
            margin-right: 5%;

            width: 70%;

            img {
              width: 100%;
              height: auto;

            }
          }
        }

        .checklist-wrapper {
          border-top: 4px solid currentColor;
          float: left;
          margin-left: $col-width / 2;
          width: 15.16666666 * $col-width;
          margin-top: 25px;
          margin-bottom: 60px;

          h2 {
            margin-top: 10px;
            font-size: 20px;

          }

          .point-checklist {

          }
        }

      }

      #latest-products {
        @extend .clearfix;
        margin-top: 7em;
        margin-bottom: 80px;
        h3 {
          margin-left: $col-width / 2;
        }
        .product {
          @extend .product-layout;
          margin-top: 0px;
        }
      }
    }

  }
}

body.shipping-page, body.thirty-point-check, body.warranty-page,
body.store-faq, body.about-page, body.terms-page {
  background: $white;

  div#content,
  .faq {
    background: $white;
  }

  div#content > div.red,
  div#content .sub-menu {
    background: $bile-blue;
  }
}

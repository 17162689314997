body {
  font-size: 16px;

  > div#site {
    > div.wrapper {
      //max-width: $max-fluid;
      margin: 0 auto;

      @media screen and (max-width: $mobile-max) {
        position: relative;
        overflow: hidden;
      }
    }
  }

  input[type=submit], input[type=button] {
    border: none;
    color: white;
    padding: 12px 12px 12px 20px;
    font-size: 17px;
    border-radius: 5px;
    width: 100%;
    max-width: 200px;
    text-align: left;
  }

}
